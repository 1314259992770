import UserRepository from "../repositories/UserRepository";
import { deserialize } from "jsonapi-fractal";
export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user"))
      : ""
  },
  getters: {
    getUser(state) {
      return state.user;
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    async loadUser({ commit }) {
      let res = await UserRepository.get();
      let user = deserialize(res.data);
      localStorage.setItem("user", JSON.stringify(user));
      commit("setUser", user);
    },

    reloadUser({ commit }, payload) {
      localStorage.setItem("user", JSON.stringify(payload));
      commit("setUser", payload);
    }
  }
};
