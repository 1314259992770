<template>
  <div class="auth-page">
    <b-container>
      <div>
        <h1 class="auth-logo flex items-center justify-center space-x-4">
          <img src="@/assets/logo.png" class="w-60" />
        </h1>
      </div>
      <Widget
        class="widget-auth mx-auto mt-4 shadow-xl"
        title="<h3 class='mt-0'>Ingreso al sistema</h3>"
        customHeader
      >
        <p class="widget-auth-info">
          Digíte su usuario y contraseña para acceder.
        </p>
        <form class="mt" @submit.prevent="login" ref="form">
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{ errorMessage }}
          </b-alert>
          <div class="form-group">
            <input
              class="form-control no-border"
              required
              name="username"
              placeholder="Usuario"
              v-model="username"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control no-border"
              ref="password"
              required
              type="password"
              name="password"
              placeholder="Contraseña"
              v-model="password"
            />
          </div>
          <b-button
            type="submit"
            size="sm"
            class="auth-btn mb-3"
            variant="inverse"
          >
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white absolute"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              v-if="isLoading"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span>Entrar</span>
          </b-button>

          <div class="password-reset text-center">
            <a v-b-modal.modal-recover-password>¿Olvidaste tu contraseña?</a>
          </div>
        </form>
      </Widget>
    </b-container>
    <modal-recover-password />
    <modal-reset-password />
  </div>
</template>

<script>
import Widget from "@/components/Widget/Widget";
import { API_ROUTES, axios } from "@/config/index.js";
import { mapActions } from "vuex";
import ModalRecoverPassword from "./modals/ModalRecoverPassword";
import ModalResetPassword from "./modals/ModalResetPassword";

// import profiles from "@/config/profiles";
export default {
  name: "LoginPage",
  components: { ModalRecoverPassword, ModalResetPassword },
  data() {
    return {
      errorMessage: null,
      title: process.env.VUE_APP_TITLE,
      username: "",
      password: "",
      isLoading: false
    };
  },
  methods: {
    ...mapActions("user", ["loadUser"]),
    async login() {
      const me = this;
      me.errorMessage = null;

      if (me.username !== "" && me.password !== "") {
        me.isLoading = true;

        try {
          let response = await axios.post(API_ROUTES.auth.login, {
            username: me.username,
            password: me.password
          });
          console.log(response);

          if (response.data.data == "you must confirm your email") {
            me.errorMessage = "Debes confirmar tu correo electrónico.";
            me.isLoading = false;
            me.username = me.password = "";
            return;
          }

          if (response.data.data == "user is not active") {
            me.errorMessage = "El usuario no está activo.";
            me.isLoading = false;
            me.username = me.password = "";

            return;
          }

          if (response.data.data == "temporal password") {
            me.isLoading = false;
            me.username = me.password = "";
            me.$bvModal.show("modal-reset-password");
            return;
          }

          localStorage.setItem("access_token", response.data.access_token);
          localStorage.setItem("authenticated", true);
          me.$store.dispatch("user/loadUser").then(() => {
            window.location.href = process.env.VUE_APP_HOME_ROUTE;
          });
        } catch (error) {
          me.errorMessage = "Usuario y/o contraseña inválidos";
          me.username = me.password = "";
        }

        me.isLoading = false;
      }
    }
  }
};
</script>
