export default {
  get() {},
  find(id, params) {},
  save(data) {},
  edit(id, data) {},
  delete(id) {},
  update(id, data) {},
  getDocuments(clientId) {},
  uploadDocument(data) {},
  deleteDocument(id) {},
  getNotes(clientId) {},
  saveNote(data) {}
};
