import { v4 as uuidv4 } from "uuid";
import { deserialize } from "jsonapi-fractal";

const moduleActions = {
  methods: {
    setSelectedRow(row) {
      const me = this;

      me.selectedRow = me.$parent.selectedRow = row;
    },

    erase(row) {
      const me = this;
      const module = me.$parent;

      me.isLoadingDeletion = true;

      module[me.moduleOptions.repository.name]
        .delete(row[me.moduleOptions.primary])
        .then(() => {
          me.isLoadingDeletion = false;
          me.$bvModal.hide("modal-delete-record");
          me.$toasted.success("Registro eliminado con éxito!");
          me.handleLoadData();
        })
        .catch(() => {
          me.isLoadingDeletion = false;
          me.$toasted.error("Ocurrió un error!");
        });
    }
  }
};

const customComponentActions = {
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.formFields[name];
      return $dirty ? !$error : null;
    },

    validateField(field) {
      return this.$root.validationMsg(field);
    },

    readOnlyOnEditing() {
      const me = this;
      return me.formFields.id !== null;
    }
  }
};

const formActions = {
  methods: {
    submit() {
      const me = this;
      const module = me.$parent;
      const form = me.$refs.form;
      let strings = module.isString ?? [];

      module.$v.$touch();

      if (!module.$v.$invalid) {
        let data = me.$formserialization.serialize(form, { hash: true });

        for (const item in data) {
          if (!isNaN(data[item]) && !strings.includes(item)) {
            data[item] = Number(data[item]);
          }
        }

        me.isLoading = true;

        if (!data.id) {
          data.id = uuidv4();
          module[module.repository]
            .save(data)
            .then(me.handleSuccess)
            .catch(me.handleFailure);
        } else {
          module[module.repository]
            .update(data.id, data)
            .then(me.handleSuccess)
            .catch(me.handleFailure);
        }
      }
    },

    handleSuccess() {
      const me = this;

      me.isLoading = false;
      me.closeModal();
      me.$emit("postSubmit");
      me.$toasted.success("Registro guardado con éxito!");
    },

    handleFailure() {
      const me = this;
      me.isLoading = false;
      me.$toasted.error("Ocurrió un error!");
    },

    closeModal() {
      const me = this;
      const module = me.$parent;

      module.formFields = { ...me.bkFormFiles };
      me.$nextTick(() => {
        module.$v.$reset();
        me.$bvModal.hide(me.id);
      });
    }
  }
};

const documentsActions = {
  methods: {
    submit() {
      const me = this;
      const baseModule = me.$parent;
      const module = baseModule.localOptions ? baseModule : baseModule.$parent;
      const form = me.$refs.form;

      const moduleOptions = baseModule.moduleOptions
        ? baseModule.moduleOptions
        : baseModule.localOptions;

      const data = me.$formserialization.serialize(form, { hash: true });
      const file = form.querySelector("input[type=file]").files[0];
      const formData = new FormData();

      if (data.name !== "" && file) {
        for (const item in data) {
          formData.append(item, data[item]);
        }

        formData.append("file", file);

        module[moduleOptions.repository.name]
          .uploadDocument(formData)
          .then(() => {
            form.querySelector("input[name=name]").value = "";
            module.$refs.file.reset();
            me.handleLoadData();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },

    deleteDoc(row) {
      const me = this;
      const baseModule = me.$parent;
      const module = baseModule.localOptions ? baseModule : baseModule.$parent;

      const moduleOptions = baseModule.moduleOptions
        ? baseModule.moduleOptions
        : baseModule.localOptions;

      module[moduleOptions.repository.name]
        .deleteDocument(row.id)
        .then(() => {
          me.handleLoadData();
          me.$bvModal.hide("modal-delete-document");
        })
        .catch(error => {
          console.log(error);
        });
    },

    closeModal() {
      const me = this;
      me.$bvModal.hide("modal-documents-form");
    },

    handleLoadData() {
      const me = this;
      const baseModule = me.$parent;
      const module = baseModule.localOptions ? baseModule : baseModule.$parent;

      const moduleOptions = baseModule.moduleOptions
        ? baseModule.moduleOptions
        : baseModule.localOptions;

      /* me.clientId = me.row[moduleOptions.primary]; */
      me.clientId = me.row.id;

      module[moduleOptions.repository.name]
        .getDocuments(me.clientId)
        .then(response => {
          me.records = deserialize(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    },

    handleShow() {
      this.handleLoadData();
    }
  }
};

const notesActions = {
  methods: {
    submit() {
      const me = this;
      const baseModule = me.$parent;
      const module = baseModule.localOptions ? baseModule : baseModule.$parent;
      const form = me.$refs.form;

      const moduleOptions = baseModule.moduleOptions
        ? baseModule.moduleOptions
        : baseModule.localOptions;

      let data = me.$formserialization.serialize(form, { hash: true });

      if (!data.id) {
        data.id = uuidv4();
        module[moduleOptions.repository.name]
          .saveNote(data)
          .then(() => {
            form.querySelector("textarea").value = "";
            me.handleLoadData();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },

    closeModal() {
      const me = this;
      me.$bvModal.hide("notes-trace-modal");
    },

    handleLoadData() {
      const me = this;
      const baseModule = me.$parent;

      const module = baseModule.localOptions ? baseModule : baseModule.$parent;
      const moduleOptions = baseModule.moduleOptions
        ? baseModule.moduleOptions
        : baseModule.localOptions;
      me.clientId = me.row[moduleOptions.primary];

      module[moduleOptions.repository.name]
        .getNotes(me.clientId)
        .then(response => {
          me.records = deserialize(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    },

    handleShow() {
      this.handleLoadData();
    }
  }
};

export {
  moduleActions,
  customComponentActions,
  formActions,
  documentsActions,
  notesActions
};
