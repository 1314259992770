export default [
  {
    path: "dashboard",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/lawyers/Dashboard/Dashboard"
      )
  },
  {
    path: "processlawyer",
    name: "Processlawyer",
    component: () =>
      import(
        /* webpackChunkName: "tracesprocesslawyers" */ "@/lawyers/ProcessLawyer/ProcessLawyer"
      ),
    meta: {
      requiresAuth: true,
      permissions: ["new", "edit", "documents", "details", "traceProcess"]
    }
  },
  {
    path: "processlawyerdetails",
    name: "processlawyerdetails",
    component: () =>
      import(
        /* webpackChunkName: "processlawyerdetails" */ "@/lawyers/ProcessLawyer/DetailsProcess"
      )
  },
  {
    path: "tracesprocesslawyers",
    name: "Tracesprocesslawyers",
    component: () =>
      import(
        /* webpackChunkName: "tracesprocesslawyers" */ "@/lawyers/Traceprocesslawyers/Traceprocesslawyers"
      ),
    meta: {
      requiresAuth: true,
      permissions: ["new", "documents", "details", "notes"]
    }
  },
  {
    path: "myprofile",
    name: "Myprofile",
    component: () =>
      import(
        /* webpackChunkName: "myprofile" */ "@/lawyers/Setting/MyProfile/MyProfile"
      )
  }
];
