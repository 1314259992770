<template>
  <div class="sidebar-wrapper">
    <nav
      :class="{ sidebar: true, sidebarStatic, sidebarOpened }"
      @mouseenter="sidebarMouseEnter"
      @mouseleave="sidebarMouseLeave"
    >
      <header class="logo">
        <div class="flex items-center justify-center space-x-2">
          <div>
            <img src="@/assets/logo.png" class="w-12" />
          </div>
          <div class="primary-word" v-if="sidebarStatic || sidebarOpened">
            Préstamos
          </div>
        </div>
      </header>
      <h5 class="navTitle first">
        NAVEGACIÓN
      </h5>
      <!-- //Menu Items -->
      <ul class="nav" v-for="(item, i) in menu" :key="i">
        <template v-if="item.isHeader">
          <NavLink
            :activeItem="activeItem"
            :header="item.header"
            :link="item.link"
            :iconName="item.iconName"
            :index="item.index"
            :isHeader="item.isHeader"
          />
        </template>
        <template v-else>
          <NavLink
            :activeItem="activeItem"
            :header="item.header"
            :link="item.link"
            :iconName="item.iconName"
            :index="item.index"
            :childrenLinks="item.childrenLinks"
          />
        </template>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import isScreen from "@/core/screenHelper";
import NavLink from "./NavLink/NavLink";
// profiles
import profiles from "@/config/profiles";

export default {
  name: "Sidebar",
  components: { NavLink },
  data() {
    return {
      menu: ""
    };
  },
  methods: {
    ...mapActions("layout", ["changeSidebarActive", "switchSidebar"]),
    setActiveByRoute() {
      const paths = this.$route.fullPath.split("/");
      paths.pop();
      this.changeSidebarActive(paths.join("/"));
    },
    sidebarMouseEnter() {
      if (!this.sidebarStatic && (isScreen("lg") || isScreen("xl"))) {
        this.switchSidebar(false);
        this.setActiveByRoute();
      }
    },
    sidebarMouseLeave() {
      if (!this.sidebarStatic && (isScreen("lg") || isScreen("xl"))) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }
    }
  },
  created() {
    this.setActiveByRoute();
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.menu = profiles[user.profile_id].menu;
    }
  },
  computed: {
    ...mapState("layout", {
      sidebarStatic: state => state.sidebarStatic,
      sidebarOpened: state => !state.sidebarClose,
      activeItem: state => state.sidebarActiveElement
    })
  }
};
</script>

<!-- Sidebar styles should be scoped -->
<style src="./Sidebar.scss" lang="scss" scoped />
