<template>
  <b-navbar class="header d-print-none app-header" :sticky="true">
    <b-nav>
      <b-nav-item>
        <a
          class="d-md-down-none px-2"
          href="#"
          @click="toggleSidebarMethod"
          id="barsTooltip"
        >
          <i class="fi flaticon-menu" />
        </a>
        <a class="fs-lg d-lg-none" href="#" @click="switchSidebarMethod">
          <i class="fi flaticon-menu" />
        </a>
      </b-nav-item>
    </b-nav>
    <a class="navbarBrand d-md-none">
      <i class="fa fa-circle text-danger" />
      &nbsp; sing &nbsp;
      <i class="fa fa-circle text-primary" />
    </a>
    <b-nav class="ml-auto">
      <b-nav-item-dropdown
        class="settingsDropdown d-sm-down-none"
        menu-class="notificationsWrapper py-0 animate__animated animate__animated-fast animate__fadeIn"
        right
      >
        <template slot="button-content">
          <b-avatar variant="dark"></b-avatar>
          <span class="px-2">{{ user.name }}</span>
          <i class="fi flaticon-arrow-down px-2" />
        </template>
        <b-dropdown-item-button @click="logout">
          <i class="fi flaticon-power-1 px-3 mr-3" /> Salir
        </b-dropdown-item-button>
      </b-nav-item-dropdown>
    </b-nav>
  </b-navbar>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Header",
  computed: {
    ...mapState("layout", ["sidebarClose", "sidebarStatic"]),
    ...mapState("user", ["user"])
  },
  methods: {
    ...mapActions("layout", [
      "toggleSidebar",
      "switchSidebar",
      "changeSidebarActive"
    ]),
    switchSidebarMethod() {
      if (!this.sidebarClose) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      } else {
        this.switchSidebar(false);
        const paths = this.$route.fullPath.split("/");
        paths.pop();
        this.changeSidebarActive(paths.join("/"));
      }
    },
    toggleSidebarMethod() {
      if (this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      } else {
        this.toggleSidebar();
        const paths = this.$route.fullPath.split("/");
        paths.pop();
        this.changeSidebarActive(paths.join("/"));
      }
    },
    logout() {
      localStorage.clear();
      window.location.reload();
    }
  }
};
</script>

<style src="./Header.scss" lang="scss"></style>
