export default [
  {
    path: "dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/admin/Dashboard/Dashboard"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "clients",
    name: "Clients",
    component: () =>
      import(/* webpackChunkName: "clients" */ "@/admin/Clients/Clients"),
    meta: {
      requiresAuth: true,
      permissions: ["new", "documents", "details", "lend"]
    }
  },
  {
    path: "enterprises",
    name: "Enterprises",
    component: () =>
      import(
        /* webpackChunkName: "enterprises" */ "@/admin/Enterprises/Enterprises"
      ),
    meta: {
      requiresAuth: true,
      permissions: ["new", "documents", "details"]
    }
  },
  {
    path: "lawyers",
    name: "Lawyers",
    component: () =>
      import(/* webpackChunkName: "lawyers" */ "@/admin/Lawyers/Lawyers"),
    meta: {
      requiresAuth: true,
      permissions: ["new", "documents", "details"]
    }
  },
  {
    path: "courts",
    name: "Courts",
    component: () =>
      import(/* webpackChunkName: "courts" */ "@/admin/Courts/Courts"),
    meta: {
      requiresAuth: true,
      permissions: ["new", "details"]
    }
  },
  {
    path: "judges",
    name: "Judges",
    component: () =>
      import(/* webpackChunkName: "judges" */ "@/admin/Judges/Judges"),
    meta: {
      requiresAuth: true,
      permissions: ["new", "details"]
    }
  },
  {
    path: "loans",
    name: "Loans",
    component: () =>
      import(/* webpackChunkName: "loans" */ "@/admin/Loans/Loans")
  },
  {
    path: "processbuy",
    name: "Processbuy",
    component: () =>
      import(
        /* webpackChunkName: "processbuy" */ "@/admin/Processbuy/Processbuy"
      )
  },
  {
    path: "legal",
    name: "Legal",
    component: () =>
      import(/* webpackChunkName: "legal" */ "@/admin/Legal/Legal")
  },
  {
    path: "property",
    name: "Property",
    component: () =>
      import(/* webpackChunkName: "property" */ "@/admin/Property/Property")
  },
  {
    path: "users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "@/admin/Setting/Users/Users")
  }
];
