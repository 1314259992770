import Vue from "vue";
import Router from "vue-router";

import Layout from "@/components/Layout/Layout";
import Login from "@/components/Login/Login";
import ErrorPage from "@/components/Error/Error";

// profiles
import profiles from "@/config/profiles";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/error",
      name: "Error",
      component: ErrorPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/",
      redirect: "/app",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/app",
      name: "Layout",
      component: Layout,
      meta: {
        requiresAuth: true
      },
      children: []
    }
  ]
});

export async function beforeEach(to, from, next) {
  if (localStorage.getItem("user")) {
    let user = JSON.parse(localStorage.getItem("user"));
    let routes = profiles[user.profile_id].routes;

    routes.forEach(route => {
      if (
        router.getRoutes().findIndex(item => item.name === route.name) === -1
      ) {
        router.addRoute("Layout", route);
      }
    });
  }

  if (router.getRoutes().findIndex(route => route.name === to.name) > -1) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!localStorage.getItem("authenticated")) {
        next({
          name: "login"
        });
      } else {
        next();
      }
    } else {
      if (
        localStorage.getItem("authenticated") === "true" &&
        to.name === "login"
      ) {
        next(process.env.VUE_APP_HOME_ROUTE);
      } else {
        next();
      }
    }
  } else {
    next({
      name: "login"
    });
  }
}

router.beforeEach((to, from, next) => beforeEach(to, from, next));

export default router;
