export default {
  vuetable: {
    texts: {
      count:
        "Mostrando {from} hasta {to} de {count} registros|{count} registros|Un registro",
      first: "Primero",
      last: "Último",
      filter: "Filtrar:",
      filterPlaceholder: "Buscar...",
      limit: "Registros:",
      page: "Página:",
      noResults: "No hay registros",
      noRequest:
        "Por favor seleccione al menos un filtro para obtener registros",
      filterBy: "Filtrar por {column}",
      loading: "Cargando...",
      defaultOption: "Seleccionar {column}",
      columns: "Columnas"
    }
  }
};
