export default [
  {
    path: "dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/admin/Dashboard/Dashboard"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "clients",
    name: "Clients",
    component: () =>
      import(/* webpackChunkName: "clients" */ "@/admin/Clients/Clients"),
    meta: {
      requiresAuth: true,
      permissions: [
        "new",
        "edit",
        "delete",
        "documents",
        "details",
        "lend",
        "process"
      ]
    }
  },
  {
    path: "enterprises",
    name: "Enterprises",
    component: () =>
      import(
        /* webpackChunkName: "enterprises" */ "@/admin/Enterprises/Enterprises"
      ),
    meta: {
      requiresAuth: true,
      permissions: ["new", "edit", "delete", "documents", "details"]
    }
  },
  {
    path: "lawyers",
    name: "Lawyers",
    component: () =>
      import(/* webpackChunkName: "lawyers" */ "@/admin/Lawyers/Lawyers"),
    meta: {
      requiresAuth: true,
      permissions: ["new", "edit", "delete", "documents", "details"]
    }
  },
  {
    path: "courts",
    name: "Courts",
    component: () =>
      import(/* webpackChunkName: "courts" */ "@/admin/Courts/Courts"),
    meta: {
      requiresAuth: true,
      permissions: ["new", "edit", "delete", "details"]
    }
  },
  {
    path: "judges",
    name: "Judges",
    component: () =>
      import(/* webpackChunkName: "judges" */ "@/admin/Judges/Judges"),
    meta: {
      requiresAuth: true,
      permissions: ["new", "edit", "delete", "details"]
    }
  },
  {
    path: "loans",
    name: "Loans",
    component: () =>
      import(/* webpackChunkName: "loans" */ "@/admin/Loans/Loans")
  },
  {
    path: "processbuy",
    name: "Processbuy",
    component: () =>
      import(
        /* webpackChunkName: "processbuy" */ "@/admin/Processbuy/Processbuy"
      )
  },
  {
    path: "processlawyer",
    name: "Processlawyer",
    component: () =>
      import(
        /* webpackChunkName: "processlawyer" */ "@/admin/Processlawyer/Processlawyer"
      ),
    meta: {
      requiresAuth: true,
      permissions: ["new", "edit", "documents", "details", "traceProcess"]
    }
  },
  {
    path: "processlawyerdetails",
    name: "processlawyerdetails",
    component: () =>
      import(
        /* webpackChunkName: "processlawyerdetails" */ "@/admin/Processlawyer/DetailsProcess"
      )
  },
  {
    path: "tracesprocesslawyers",
    name: "Tracesprocesslawyers",
    component: () =>
      import(
        /* webpackChunkName: "tracesprocesslawyers" */ "@/admin/Traceprocesslawyers/Traceprocesslawyers"
      ),
    meta: {
      requiresAuth: true,
      permissions: ["new", "documents", "details", "notes"]
    }
  },
  {
    path: "legal",
    name: "Legal",
    component: () =>
      import(/* webpackChunkName: "legal" */ "@/admin/Legal/Legal")
  },
  {
    path: "property",
    name: "Property",
    component: () =>
      import(/* webpackChunkName: "property" */ "@/admin/Property/Property")
  },
  {
    path: "users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "@/admin/Setting/Users/Users"),
    meta: {
      requiresAuth: true,
      permissions: ["new", "edit", "delete", "details"]
    }
  },
  {
    path: "concepts",
    name: "Concepts",
    component: () =>
      import(
        /* webpackChunkName: "concepts" */ "@/admin/Setting/Concepts/Concepts"
      ),
    meta: {
      requiresAuth: true,
      permissions: ["edit"]
    }
  },
  {
    path: "processtypes",
    name: "Processtypes",
    component: () =>
      import(
        /* webpackChunkName: "concepts" */ "@/admin/Setting/ProcessType/ProcessType"
      ),
    meta: {
      requiresAuth: true,
      permissions: ["new", "edit", "delete"]
    }
  },
  {
    path: "myprofile",
    name: "Myprofile",
    component: () =>
      import(
        /* webpackChunkName: "myprofile" */ "@/admin/Setting/MyProfile/MyProfile"
      )
  }
];
