import BaseModuleRepositoryInterface from "@/repositories/interfaces/BaseModuleRepositoryInterface.js";

const bind = (repositoryName, Interface) => {
  return {
    ...Object.keys(Interface).reduce((prev, method) => {
      const resolveableMethod = async (...args) => {
        const repository = await import(`@/repositories/${repositoryName}`);
        return repository.default[method](...args);
      };
      return { ...prev, [method]: resolveableMethod };
    }, {})
  };
};

export default {
  citiesRepository: bind("CitiesRepository", BaseModuleRepositoryInterface),
  clientsRepository: bind("ClientsRepository", BaseModuleRepositoryInterface),
  enterprisesRepository: bind(
    "EnterprisesRepository",
    BaseModuleRepositoryInterface
  ),
  lawyersRepository: bind("LawyersRepository", BaseModuleRepositoryInterface),
  loansRepository: bind("LoansRepository", BaseModuleRepositoryInterface),
  loanPaymentsRepository: bind(
    "LoanPaymentsRepository",
    BaseModuleRepositoryInterface
  ),
  userRepository: bind("UserRepository", BaseModuleRepositoryInterface),
  conceptsRepository: bind("ConceptsRepository", BaseModuleRepositoryInterface),
  usersRepository: bind("UsersRepository", BaseModuleRepositoryInterface),
  profilesRepository: bind("ProfilesRepository", BaseModuleRepositoryInterface),
  judgesRepository: bind("JudgesRepository", BaseModuleRepositoryInterface),
  courtsRepository: bind("CourtsRepository", BaseModuleRepositoryInterface),
  processTypeRepository: bind(
    "ProcessTypesRepository",
    BaseModuleRepositoryInterface
  ),
  processLawyersRepository: bind(
    "ProcessLawyersRepository",
    BaseModuleRepositoryInterface
  ),
  traceprocessLawyersRepository: bind(
    "TraceProcessLawyersRepository",
    BaseModuleRepositoryInterface
  ),
  processLawyerRepository: bind(
    "ProcessLawyerRepository",
    BaseModuleRepositoryInterface
  ),
  overdueLoansRepository: bind(
    "OverdueLoansRepository",
    BaseModuleRepositoryInterface
  ),
  expiredTracesProcessRepository: bind(
    "ExpiredTracesProcessRepository",
    BaseModuleRepositoryInterface
  ),
  notesTracesProcessRepository: bind(
    "NotesTracesProcessRepository",
    BaseModuleRepositoryInterface
  ),
  stateProcessRepository: bind(
    "StateProcessRepository",
    BaseModuleRepositoryInterface
  ),
  typeTracesProcessRepository: bind(
    "TypeTracesProcessRepository",
    BaseModuleRepositoryInterface
  ),
  loansDueRepository: bind("LoansDueRepository", BaseModuleRepositoryInterface),
  loansDueTodayRepository: bind(
    "LoansDueTodayRepository",
    BaseModuleRepositoryInterface
  ),
  audiencesNextRepository: bind(
    "AudiencesNextRepository",
    BaseModuleRepositoryInterface
  ),
  audiencesTodayRepository: bind(
    "AudiencesTodayRepository",
    BaseModuleRepositoryInterface
  ),
  audiencesExpiredRepository: bind(
    "AudiencesExpiredRepository",
    BaseModuleRepositoryInterface
  ),
  processLawyerExpiredRepository: bind(
    "ProcessLawyerExpiredRepository",
    BaseModuleRepositoryInterface
  ),
  audiencesExpiredLawyerRepository: bind(
    "AudiencesExpiredLawyerRepository",
    BaseModuleRepositoryInterface
  ),
  audiencesNextLawyerRepository: bind(
    "AudiencesNextLawyerRepository",
    BaseModuleRepositoryInterface
  ),
  audiencesTodayLawyerRepository: bind(
    "AudiencesTodayLawyerRepository",
    BaseModuleRepositoryInterface
  )
};
