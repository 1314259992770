const formMessages = {
  required: () => "Campo requerido",
  minLength: ({ $params }) =>
    `Debe contener como mínimo ${$params.minLength.min} caracteres`,
  maxLength: ({ $params }) =>
    `Debe contener como máximo ${$params.maxLength.max} caracteres`,
  email: () => "email inválido"
};

export default formMessages;
