// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import * as VueGoogleMaps from "vue2-google-maps";
import VueTouch from "vue-touch";
import Trend from "vuetrend";
import Toasted from "vue-toasted";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import { validationMessage } from "vuelidate-messages";
import FormValidationMessages from "@/config/form-validation-messages.js";
const formserialization = require("form-serialization");

import store from "./store";
import router from "./Routes";
import App from "./App";
import layoutMixin from "./mixins/layout";
import Widget from "./components/Widget/Widget";

import RepositoryProvider from "@/providers/RepositoryProvider";
import { ClientTable } from "vue-tables-2";
import esLang from "./lang/es";

import vSelect from "vue-select";
import VueFilterNumericFormat from "@vuejs-community/vue-filter-numeric-format";

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

import "@/assets/css/tailwind.css";
import "@/styles/theme.scss";

Vue.use(BootstrapVue);
Vue.use(VueTouch);
Vue.use(Trend);
Vue.component("Widget", Widget);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg"
  }
});
Vue.component("apexchart", VueApexCharts);
Vue.mixin(layoutMixin);
Vue.use(Toasted, { duration: 3000, position: "bottom-right" });
Vue.use(
  ClientTable,
  {
    ...esLang.vuetable
  },
  false,
  "bootstrap4"
);

Vue.use(Vuelidate);
Vue.component("v-select", vSelect);
Vue.prototype.$formserialization = formserialization;
Vue.use(CoolLightBox);
Vue.use(VueFilterNumericFormat, {
  decimalSeparator: ",",
  fractionDigitsMax: 0,
  fractionDigitsMin: 0,
  fractionDigitsSeparator: "",
  thousandsDigitsSeparator: "."
});

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  provide: RepositoryProvider,
  store,
  router,
  methods: {
    validationMsg: validationMessage(FormValidationMessages)
  },
  render: h => h(App)
});
