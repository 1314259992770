// Routes
import adminRoutes from "./adminRoutes";
import auxRoutes from "./auxRoutes";
import lawyerRoutes from "./lawyerRoutes";
//Menus
import adminMenu from "./adminMenu";
import lawyerMenu from "./lawyerMenu";

const profiles = {
  "95b99ed8-9a1b-47c0-8a12-a5895c7a88b4": {
    routes: adminRoutes,
    menu: adminMenu
  }, //superadmin
  "5fd4afd4-4a66-4832-b149-60409e1db24f": {
    routes: auxRoutes,
    menu: adminMenu
  }, //auxiliar
  "857014a2-e76f-40c0-a211-80db30994518": {
    routes: lawyerRoutes,
    menu: lawyerMenu
  } // Lawyer
};

export default profiles;
