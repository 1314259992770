const API_URL = process.env.VUE_APP_API_URL;
const API_VERSION = process.env.VUE_APP_API_VERSION;

const API_ROUTES = {
  auth: {
    login: `${API_URL}/api/${API_VERSION}/login`,
    logout: `${API_URL}/api/${API_VERSION}/logout`
  },
  recoverPassword: {
    save: `${API_URL}/api/${API_VERSION}/getmail`
  },
  resetPassword: {
    save: `${API_URL}/api/${API_VERSION}/restorepassword`
  },
  cities: {
    get: `${API_URL}/api/${API_VERSION}/cities`
  },
  clients: {
    get: `${API_URL}/api/${API_VERSION}/clients`,
    save: `${API_URL}/api/${API_VERSION}/clients`,
    delete: `${API_URL}/api/${API_VERSION}/clients`,
    update: `${API_URL}/api/${API_VERSION}/clients`
  },
  enterprises: {
    get: `${API_URL}/api/${API_VERSION}/enterprises`,
    save: `${API_URL}/api/${API_VERSION}/enterprises`,
    delete: `${API_URL}/api/${API_VERSION}/enterprises`,
    update: `${API_URL}/api/${API_VERSION}/enterprises`
  },
  lawyers: {
    get: `${API_URL}/api/${API_VERSION}/lawyers`,
    save: `${API_URL}/api/${API_VERSION}/lawyers`,
    delete: `${API_URL}/api/${API_VERSION}/lawyers`,
    update: `${API_URL}/api/${API_VERSION}/lawyers`
  },
  clientDocuments: {
    get: `${API_URL}/api/${API_VERSION}/clientdocuments/client`,
    save: `${API_URL}/api/${API_VERSION}/clientdocuments`,
    delete: `${API_URL}/api/${API_VERSION}/clientdocuments`
  },
  enterpriseDocuments: {
    get: `${API_URL}/api/${API_VERSION}/enterprisedocuments/enterprise`,
    save: `${API_URL}/api/${API_VERSION}/enterprisedocuments`,
    delete: `${API_URL}/api/${API_VERSION}/enterprisedocuments`
  },
  lawyerDocuments: {
    get: `${API_URL}/api/${API_VERSION}/lawyerdocuments/lawyer`,
    save: `${API_URL}/api/${API_VERSION}/lawyerdocuments`,
    delete: `${API_URL}/api/${API_VERSION}/lawyerdocuments`
  },
  judges: {
    get: `${API_URL}/api/${API_VERSION}/judges`,
    save: `${API_URL}/api/${API_VERSION}/judges`,
    delete: `${API_URL}/api/${API_VERSION}/judges`,
    update: `${API_URL}/api/${API_VERSION}/judges`
  },
  courts: {
    get: `${API_URL}/api/${API_VERSION}/courts`,
    save: `${API_URL}/api/${API_VERSION}/courts`,
    delete: `${API_URL}/api/${API_VERSION}/courts`,
    update: `${API_URL}/api/${API_VERSION}/courts`
  },
  loans: {
    get: `${API_URL}/api/${API_VERSION}/loans/client`,
    save: `${API_URL}/api/${API_VERSION}/loans`
  },
  loanPayments: {
    save: `${API_URL}/api/${API_VERSION}/loanpayments`
  },
  loanDocuments: {
    get: `${API_URL}/api/${API_VERSION}/loandocuments/loan`,
    save: `${API_URL}/api/${API_VERSION}/loandocuments`,
    delete: `${API_URL}/api/${API_VERSION}/loandocuments`
  },
  user: {
    get: `${API_URL}/api/${API_VERSION}/user`,
    update: `${API_URL}/api/${API_VERSION}/users`
  },
  profiles: {
    get: `${API_URL}/api/${API_VERSION}/profiles`
  },
  setting: {
    concepts: {
      get: `${API_URL}/api/${API_VERSION}/concepts`,
      update: `${API_URL}/api/${API_VERSION}/concepts`
    },
    users: {
      get: `${API_URL}/api/${API_VERSION}/users`,
      save: `${API_URL}/api/${API_VERSION}/users`,
      update: `${API_URL}/api/${API_VERSION}/users`,
      delete: `${API_URL}/api/${API_VERSION}/users`
    },
    processtypes: {
      get: `${API_URL}/api/${API_VERSION}/processtypes`,
      save: `${API_URL}/api/${API_VERSION}/processtypes`,
      update: `${API_URL}/api/${API_VERSION}/processtypes`,
      delete: `${API_URL}/api/${API_VERSION}/processtypes`
    }
  },
  processlawyers: {
    get: `${API_URL}/api/${API_VERSION}/processlawyers`,
    save: `${API_URL}/api/${API_VERSION}/processlawyers`,
    delete: `${API_URL}/api/${API_VERSION}/processlawyers`,
    update: `${API_URL}/api/${API_VERSION}/processlawyers`
  },
  processlawyer: {
    get: `${API_URL}/api/${API_VERSION}/processlawyers/lawyer`,
    save: `${API_URL}/api/${API_VERSION}/processlawyers`,
    delete: `${API_URL}/api/${API_VERSION}/processlawyers`,
    update: `${API_URL}/api/${API_VERSION}/processlawyers`
  },
  processlawyersDocuments: {
    get: `${API_URL}/api/${API_VERSION}/processlawyerdocuments/processlawyer`,
    save: `${API_URL}/api/${API_VERSION}/processlawyerdocuments`,
    delete: `${API_URL}/api/${API_VERSION}/processlawyerdocuments`
  },
  traceprocesslawyers: {
    get: `${API_URL}/api/${API_VERSION}/processtraces/processlawyer`,
    save: `${API_URL}/api/${API_VERSION}/processtraces`,
    delete: `${API_URL}/api/${API_VERSION}/processtraces`,
    update: `${API_URL}/api/${API_VERSION}/processtraces`
  },
  traceprocesslawyersDocuments: {
    get: `${API_URL}/api/${API_VERSION}/processtracedocuments/processtrace`,
    save: `${API_URL}/api/${API_VERSION}/processtracedocuments`,
    delete: `${API_URL}/api/${API_VERSION}/processtracedocuments`
  },
  traceprocesslawyersNotes: {
    get: `${API_URL}/api/${API_VERSION}/notes/processtrace`,
    save: `${API_URL}/api/${API_VERSION}/notes`
  },
  overdueloans: {
    get: `${API_URL}/api/${API_VERSION}/loans-expired/previous`
  },
  loansdue: {
    get: `${API_URL}/api/${API_VERSION}/loans-expired`
  },
  loansduetoday: {
    get: `${API_URL}/api/${API_VERSION}/loans-expired/today`
  },
  statesprocesses: {
    get: `${API_URL}/api/${API_VERSION}/concepts/stateprocesslawyer`
  },
  typetracesprocesses: {
    get: `${API_URL}/api/${API_VERSION}/concepts/typeprocesstraces`
  },
  processlawyerOne: {
    get: `${API_URL}/api/${API_VERSION}/processlawyers`
  },
  audiencestoday: {
    get: `${API_URL}/api/${API_VERSION}/audiences/today`
  },
  audiencesnext: {
    get: `${API_URL}/api/${API_VERSION}/audiences/next`
  },
  audiencesexpired: {
    get: `${API_URL}/api/${API_VERSION}/processtraceexpired`
  },
  audiencesexpiredlawyer: {
    get: `${API_URL}/api/${API_VERSION}/processtraceexpired`
  },
  audiencesnextlawyer: {
    get: `${API_URL}/api/${API_VERSION}/audiences/next`
  },
  audiencestodaylawyer: {
    get: `${API_URL}/api/${API_VERSION}/audiences/today`
  },
  processlawyerexpired: {
    get: `${API_URL}/api/${API_VERSION}/processlawyerexpired`
  }
};

export { API_ROUTES };
