export default [
  {
    header: "Dashboard",
    link: "/app/dashboard",
    iconName: "fa-tachometer-alt",
    index: "dashboard",
    isHeader: true
  },
  {
    header: "Registros",
    link: "/app/modules",
    iconName: "fa-layer-group",
    index: "modules",
    childrenLinks: [
      { header: "Clientes", link: "/app/clients" },
      { header: "Empresas", link: "/app/enterprises" },
      { header: "Abogados", link: "/app/lawyers" },
      { header: "Juzgados", link: "/app/courts" },
      { header: "Jueces", link: "/app/judges" }
    ]
  },
  {
    header: "Préstamos",
    link: "/app/loans",
    iconName: "fa-money-bill-wave",
    index: "loans",
    isHeader: true
  },
  // {
  //   header: "Compra de procesos",
  //   link: "/app/processbuy",
  //   iconName: "flaticon-equal-1",
  //   index: "processbuy",
  //   isHeader: true
  // },
  {
    header: "Procesos",
    link: "/app/processlawyer",
    iconName: "fa-balance-scale",
    index: "processlawyer",
    isHeader: true
  },
  // {
  //   header: "Jurídicos",
  //   link: "/app/legal",
  //   iconName: "flaticon-bell",
  //   index: "legal",
  //   isHeader: true
  // },
  // {
  //   header: "Inmuebles",
  //   link: "/app/property",
  //   iconName: "flaticon-bell",
  //   index: "property",
  //   isHeader: true
  // },
  {
    header: "Configuración",
    link: "/app/settings",
    iconName: "fas fa-cog",
    index: "settings",
    childrenLinks: [
      { header: "Tipo de procesos", link: "/app/processtypes" },
      { header: "Conceptos", link: "/app/concepts" },
      { header: "Usuarios", link: "/app/users" },
      { header: "Mi perfil", link: "/app/myprofile" }
    ]
  }
];
