export default [
  {
    activeItem: "activeItem",
    header: "Dashboard",
    link: "dashboard",
    iconName: "fa-tachometer-alt",
    index: "dashboard",
    isHeader: true
  },
  {
    header: "Procesos",
    link: "/app/processlawyer",
    iconName: "fa-balance-scale",
    index: "processlawyer",
    isHeader: true
  },
  {
    header: "Configuración",
    link: "/app/settings",
    iconName: "fas fa-cog",
    index: "settings",
    childrenLinks: [{ header: "Mi perfil", link: "/app/myprofile" }]
  }
];
