<template>
  <b-modal
    id="modal-reset-password"
    title="Restablecer contraseña"
    :no-close-on-backdrop="true"
    body-class="position-static"
  >
    <div class="my-4">
      <b-form @submit.stop.prevent="submit" ref="form">
        <div class="flex space-x-4">
          <b-form-group
            label="Usuario"
            class="flex-1 required"
            :invalidFeedback="validateField($v.formFields.username)"
          >
            <b-form-input
              name="username"
              v-model.trim="$v.formFields.username.$model"
              :state="validateState('username')"
            >
            </b-form-input>
          </b-form-group>
        </div>
        <div class="flex space-x-4">
          <b-form-group
            label="Contraseña"
            class="flex-1 required"
            :invalidFeedback="validateField($v.formFields.password)"
          >
            <b-form-input
              type="password"
              name="password"
              v-model.trim="$v.formFields.password.$model"
              :state="validateState('password')"
            >
            </b-form-input>
          </b-form-group>
        </div>
        <div class="flex space-x-4">
          <b-form-group
            label="Confirmar contraseña"
            class="flex-1 required"
            :invalidFeedback="
              validateField($v.formFields.password_confirmation)
            "
          >
            <b-form-input
              type="password"
              name="password_confirmation"
              v-model.trim="$v.formFields.password_confirmation.$model"
              :state="validateState('password_confirmation')"
            >
            </b-form-input>
          </b-form-group>
        </div>
      </b-form>
    </div>
    <template #modal-footer>
      <b-button size="sm" variant="success" @click="submit">
        Restablecer
      </b-button>
    </template>
    <b-overlay
      :show="isLoading"
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      rounded="sm"
      no-wrap
    >
    </b-overlay>
  </b-modal>
</template>

<script>
import { API_ROUTES, axios } from "@/config/index.js";
import { required } from "vuelidate/lib/validators";
import { customComponentActions } from "@/mixins/actions.js";
import Swal from "sweetalert2";
export default {
  mixins: [customComponentActions],

  data() {
    return {
      isLoading: false,
      formFields: {
        username: "",
        password: "",
        password_confirmation: ""
      }
    };
  },

  validations: {
    formFields: {
      username: {
        required
      },
      password: {
        required
      },
      password_confirmation: {
        required
      }
    }
  },

  methods: {
    async submit() {
      const me = this;
      me.$v.$touch();

      if (!me.$v.$invalid) {
        me.isLoading = true;
        try {
          let response = await axios.post(API_ROUTES.resetPassword.save, {
            username: me.formFields.username,
            password: me.formFields.password,
            password_confirmation: me.formFields.password_confirmation
          });

          me.$v.formFields.$reset();

          me.isLoading = false;
          me.formFields.username = "";
          me.formFields.password = "";
          me.formFields.password_confirmation = "";
          if (response.data.data == null) {
            Swal.fire({
              icon: "error",
              title: "¡Contraseña no restablecida!",
              text: "Ha ocurrido un error, intentalo nuevamente.",
              confirmButtonColor: "#059669"
            });
          } else {
            me.$bvModal.hide("modal-reset-password");
            Swal.fire({
              icon: "success",
              title: "Contraseña Restablecida!",
              text:
                "Tu contraseña fue restablecida, te invitamos a iniciar sesión.",
              confirmButtonColor: "#059669"
            });
          }
        } catch (error) {
          me.isLoading = false;
        }
      }
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.formFields[name];
      return $dirty ? !$error : null;
    },

    validateField(field) {
      return this.$root.validationMsg(field);
    }
  }
};
</script>
