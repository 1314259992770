<template>
  <b-modal
    id="modal-recover-password"
    title="Recuperar contraseña"
    :no-close-on-backdrop="true"
    body-class="position-static"
  >
    <div class="my-4">
      <b-form @submit.stop.prevent="submit" ref="form">
        <div class="flex space-x-4">
          <b-form-group
            label="Usuario"
            class="flex-1 required"
            :invalidFeedback="validateField($v.formFields.username)"
          >
            <b-form-input
              name="username"
              v-model.trim="$v.formFields.username.$model"
              :state="validateState('username')"
            >
            </b-form-input>
          </b-form-group>
        </div>
      </b-form>
    </div>
    <template #modal-footer>
      <b-button size="sm" variant="success" @click="submit">
        Enviar
      </b-button>
    </template>
    <b-overlay
      :show="isLoading"
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      rounded="sm"
      no-wrap
    >
    </b-overlay>
  </b-modal>
</template>

<script>
import { API_ROUTES, axios } from "@/config/index.js";
import { required } from "vuelidate/lib/validators";
import { customComponentActions } from "@/mixins/actions.js";
import Swal from "sweetalert2";
export default {
  mixins: [customComponentActions],

  data() {
    return {
      isLoading: false,
      formFields: {
        username: ""
      }
    };
  },

  validations: {
    formFields: {
      username: {
        required
      }
    }
  },

  methods: {
    async submit() {
      const me = this;
      me.$v.$touch();

      if (!me.$v.$invalid) {
        me.isLoading = true;
        try {
          let response = await axios.post(API_ROUTES.recoverPassword.save, {
            username: me.formFields.username
          });

          me.$v.formFields.$reset();

          me.isLoading = false;
          me.formFields.username = "";

          if (response.data.data == null) {
            Swal.fire({
              icon: "error",
              title: "¡Usuario no existe!",
              text: "Digíte un usuario registrado.",
              confirmButtonColor: "#059669"
            });
          } else {
            me.$bvModal.hide("modal-recover-password");
            Swal.fire({
              icon: "success",
              title: "¡Recuperación Exitosa!",
              text:
                "Le hemos enviado un correo con una contraseña temporal, si no ve el correo en su bandeja principal por favor revise su carpeta de SPAM. Gracias!",
              confirmButtonColor: "#059669"
            });
          }
        } catch (error) {
          me.isLoading = false;
        }
      }
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.formFields[name];
      return $dirty ? !$error : null;
    },

    validateField(field) {
      return this.$root.validationMsg(field);
    }
  }
};
</script>
